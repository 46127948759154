import React from 'react'

const Sidebar = ({ setActiveMenuLabel }) => {
    return (
        <section class="drawer-list">
            <ul>
                <li><a href="#" onClick={() => setActiveMenuLabel('Piva')}>Piva</a></li>
                <li><a href="#" onClick={() => setActiveMenuLabel('Sudy')}>Sudy</a></li>
                <li><a href="#" onClick={() => setActiveMenuLabel('Výčepy')}>Výčepní zařízení</a></li>
            </ul>
      </section>
    )
}

export default Sidebar