import React from 'react';



const TopNavBar = ({ tabs }) => {
    return (
        <nav className="items-center row no-wrap text-grey" role="tablist">
            <div className="nav-tabs hide-scrollbar items-center no-wrap row">
                
                {tabs.map((tab, i) => (
                    <div tabIndex={i === 0 ? -1 : 0} 
                        className={`nav-tab flex flex-center text-center btn non-selectable focusable hoverable ${i === 0 && 'active'}`}
                    >
                        <div tabIndex={i === 0 ? -1 : 0} className="focus-helper">
                        </div>
                        
                        <div className="nav-tab-content flex-center non-selectable row no-wrap">
                            <div className="nav-tab-label">{tab}</div>
                        </div>
                    </div>
                ))}
            </div>
        </nav>
    )
}

export default TopNavBar;;