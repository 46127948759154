import React from 'react'

const Product = ({product}) => {
    return (
        <div className="product">
            <div className=" product-container row">
                <div>
                    <div role="img" className="product-photo">
                        <div style={{paddingBottom: '100%'}}></div>
                        <div className="photo" style={{
                            // Fallback
                            backgroundImage: 'url("https://img.beershop.cz/images/IMG_9272.jpg")',
                            backgroundImage: 'linear-gradient(0deg, rgba(221,221,221,1) 0%, rgba(221,221,221,0) 15%), url("https://img.beershop.cz/images/IMG_9272.jpg")'
                        }}></div>
                    </div>
                </div>
                <div className="info col">
                    <h6 className="product-title">
                        {product.name}
                    </h6>
                    <p className="product-style">
                        {product.style}
                    </p>
                    {/* <br/> */}
                    <p className="product-annotation">
                    Lehký, ale přesto poctivý a chutný Pale Ale od ...
                </p>
                    <div className="items-center justify-between wrap row">
                        <p className="product-price">
                            {product.price}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Product