import React from 'react'
import TopNavBar from './TopNavBar'
import Product from '../components/Product'

const Content = () => {

    const menuItems = ['Piva', 'Sudy', 'Výčepní zařízení'];

    const beerManufacturers = ['Bevog', 'Boon', 'CoolHead', 'Krecher', 'Omnipollo', 'Salama', 'To', 'Stigberget'];

    const product = {
        name: 'Nitro Stout',
        style: 'Stout Nitro, 12°',
        price: '89,00 Kč'
    };

    return (
        <div className="container">
            <main>
                <TopNavBar tabs={beerManufacturers} />
                <div className="shadow"></div>
                <div className="cover"></div>
                <div id="content-container">
                    { beerManufacturers.map((tab, i) => (
                        <div id={tab}>
                            <div>
                                <h2 className="category-name">{tab}</h2>
                                <div className="row">
                                {[...Array(10)].map((x, i) =>  (
                                    <Product product={product} />
                                ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </main>

        </div>
    )
}

export default Content