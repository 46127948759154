import './scss/style.scss';

import Header from './layout/Header'
import Content from './layout/Content'

function App() {
  return (
    <div id="layout">
      <Header />
      <Content />

    </div>
  );
}

export default App;
